import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../components/Navbar/Navbar";
import Carousel from "../components/Carousel/Carousel";
import ProductCard from "../components/ProductCard/ProductCard";
import "./Home.css";
import Footer from "../components/Footer/Footer";
import { FaWhatsapp, FaPhoneAlt, FaLinkedin } from "react-icons/fa";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
import { scrollToTop } from "../utils/scroll";

const Home = () => {
  return (
    <div>
      {/* Start of Navbar */}
      <Navbar />
      {/* End of Navbar */}
      {/* Start of Carousel */}
      <Carousel />
      {/* End of Carousel */}
      {/* About Us */}
      <section id="about_us" className="py-5">
        <Container>
          <Row>
            <Col lg={6}>
              <h5 className="display-6 text-start fw-bold">About Us</h5>
              <p className="mt-3 lh-lg">
                Welcome to KG PACKERS, a premier name in the corrugated box
                manufacturing industry. Established in 1999, we have built a
                legacy of excellence over the past 25 years. As a global
                supplier based in India, we are dedicated to delivering
                high-quality, innovative packaging solutions that cater to the
                diverse needs of our clients worldwide. KG PACKERS began its
                journey with a vision to redefine packaging standards. Over the
                years, we have evolved and expanded, always staying at the
                forefront of the industry. Our commitment to quality,
                innovation, and customer satisfaction has been the cornerstone
                of our success. We specialize in a comprehensive range of
                corrugated packaging products designed to meet various
                requirements:
              </p>
            </Col>
            <Col className="d-flex align-items-center justify-content-center">
              <LazyLoadImage effect="blur" src="/images/celebration.webp" className="branding_image" alt="celebrating 25 years" />
            </Col>
          </Row>
        </Container>
      </section>
      {/* End of About Us */}
      {/* Start of Products */}
      <section
        id="products"
        className="py-5"
        style={{
          backgroundColor: "#ebddd0",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h5 className="display-6 text-start fw-bold">Products</h5>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row className="mt-5 mb-5 d-flex justify-content-around equal-height">
            <Col md={5}>
              <ProductCard
                title="Corrugated Boxes"
                description="Choose from 3, 5, or 7 ply boxes tailored to meet a range of lightweight to heavy-duty requirements. We provide printing solutions ranging from single color to fully customized multi-color prints."
                image="/images/corrugated-boxes.webp"
              />
            </Col>
            <Col md={5}>
              <ProductCard
                title="Corrugated Rolls"
                description="Can be manufactured to your specifications with a variety of GSM and BF options. Easily adapts to any shape. Ideal for cushioning and shock absorption, making it perfect for packaging fragile items and irregularly shaped products."
                image="/images/corrugated-rolls.webp"
              />
            </Col>
          </Row>
          <Row className="mt-5 mb-5 d-flex justify-content-around equal-height">
            <Col md={5}>
              <ProductCard
                title="Corrugated Boards"
                description="We offer an extensive range of corrugated paper boards, specially designed for creating attractive corrugated boxes. Catering to your diverse requirements, these boards are available in various qualities and dimensions to meet your specific needs."
                image="/images/corrugated-boards.webp"
              />
            </Col>
            <Col md={5}>
              <ProductCard
                title="Mono-Cartons"
                description="Eye-catching promotional packaging for compact products like medicines, cosmetics, and food enhances product appeal and brand visibility. Designed to protect and present items effectively, ensuring they stand out in retail and online environments."
                image="/images/mono-carton.webp"
              />
            </Col>
          </Row>
          <div className="text-center">
            <Link to="/products" onClick={scrollToTop}>
              <button className="mt-4 load_more_btn">Load More</button>
            </Link>
          </div>
        </Container>
      </section>
      {/* End of Products */}
      {/* Footer */}
      <Footer />
      {/* End of Footer */}
      <div className="social__icons">
        <Link
          to="https://www.linkedin.com/company/kg-packers/mycompany/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="linkedin__icon">
            <FaLinkedin size={25} />
          </span>
        </Link>
        <Link
          to="https://wa.me/+919446097686"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="whatsapp__icon">
            <FaWhatsapp size={35} />
          </span>
        </Link>
        <Link to="tel:+919446097686">
          <span className="call__icon">
            <FaPhoneAlt size={25} />
          </span>
        </Link>
      </div>
    </div>
  );
};

export default React.memo(Home);

import React from "react";
import { Carousel, Image } from "react-bootstrap";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "./Carousel.css";

const CarouselComponent = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <div className="carousel-container">
          <LazyLoadImage
            src="/images/carousel-1.webp"
            effect="blur"
            className="carousel-image"
            height="85vh"
            width="100vw"
            alt="Trusted packaging since 1999"
          />
          <div className="carousel-overlay"></div>
        </div>
        <Carousel.Caption>
          <Image src="/images/branding.webp" fluid style={{
            height:"8rem"
          }} />
          <h3 className="display-5 text-white fw-bold text-capitalize">
            Trusted Packaging Since 1999
          </h3>
          <p
            className="text-white fw-bold mt-3"
            style={{
              fontSize: "1.5rem",
            }}
          >
            All types of Corrugated boxes, Rolls, Die-Cut boxes, Mono-Cartons,
            etc.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <LazyLoadImage
          src="/images/carousel-2.webp"
          effect="blur"
            className="carousel-image"
            alt="our mission carousel"
            height="85vh"
            width="100vw"
          style={{  opacity:0.6 }}
        />
        <Carousel.Caption>
          <h3 className="display-2 text-black fw-bold text-capitalize">
            Our Mission
          </h3>
          <p
            className="text-black fw-bold mt-4 lh-lg"
            style={{
              fontSize: "1.5rem",
            }}
          >
            Our mission is to provide superior packaging solutions that enhance
            our clients' operations and protect their products. We are committed
            to quality, sustainability, and innovation, ensuring that we meet
            and exceed our clients' expectations.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default React.memo(CarouselComponent);

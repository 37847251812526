import React from "react";
import { Row, Col } from "react-bootstrap";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "./ProductCard.css";

const ProductCard = ({title, description, image}) => {
  return (
    <Row className="product_container">
      <Col>
        <h5 className="fw-bold">{title}</h5>
        <p>
          {description}
        </p>
      </Col>
      <Col className='d-flex justify-content-center align-items-center'>
        <LazyLoadImage 
        effect="blur"
        src={image} 
        style={{ height: "200px" }}
        height="200px"
        alt={description}
         />
      </Col>
    </Row>
  );
};

export default React.memo(ProductCard);

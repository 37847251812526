import React, { useRef, useState } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import { notification } from "../../utils/notification";

import "./ContactModal.css";

const ContactModal = ({ show, setShow }) => {
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const companyRef = useRef();
  const detailsRef = useRef();

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    if (!validateForm()) {
      notification("Please fill all mandatory fields in the form.", "error");
      setLoading(false);
      return; // Exit function if validation fails
    }

    const formData = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      phoneNumber: phoneRef.current.value,
      company: companyRef.current.value,
      otherDetails: detailsRef.current.value,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_FRONTEND_URL}/api/sendContactInfo`,
        formData
      );
      if (response.status === 200) {
        notification("Email send successfully", "success");
      }
    } catch (error) {
      console.log("ERROR", error);
      notification(
        "500 - Internal Server Error, Please try after some time.",
        "error"
      );
    }

    setLoading(false);
    setShow(false);
  };

  const validateForm = () => {
    // Retrieve form values from refs
    const name = nameRef.current.value.trim();
    const email = emailRef.current.value.trim();
    const phone = phoneRef.current.value.trim();
    const company = companyRef.current.value.trim();

    // Validation rules
    if (name === "") {
      return false;
    }

    // Email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return false;
    }

    // Phone number validation (basic check)
    if (phone === "") {
      return false;
    }

    // Company name or website validation
    if (company === "") {
      return false;
    }

    // If all validations pass, return true
    return true;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Get a Quote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>
                Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                autoFocus
                ref={nameRef}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Email address <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                ref={emailRef}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPhone">
              <Form.Label>
                Phone Number <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone Number"
                ref={phoneRef}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCompanyName">
              <Form.Label>
                Company name or company website{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Company name or company website"
                ref={companyRef}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicOtherDetails">
              <Form.Label>Other Details</Form.Label>
              <textarea
                className="form-control"
                id="formBasicOtherDetails"
                rows="3"
                placeholder="Other Details"
                ref={detailsRef}
              ></textarea>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Row className="d-flex align-items-center justify-content-between flex-fill">
            <Col md={6}>
              <div className="d-grid gap-2">
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-grid gap-2">
                <Button
                  variant="primary"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(ContactModal);

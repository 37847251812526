import React, { useState } from "react";
import { Navbar, Container, Nav, Image } from "react-bootstrap";
import ContactModal from "../ContactModal/ContactModal.jsx";
import { Link } from "react-router-dom";
import "./Navbar.css";

const NavbarComponent = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Navbar expand="lg">
        <Container fluid className="mx-5">
          <Link to={"/"} >
            <Image src="/images/logo.webp" className="me-2 navbar__logo" />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav
              className="ml-auto"
              style={{
                fontSize: "1.1rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Link to="/" className="me-3 text-decoration-none text-black">
                Home
              </Link>
              <Link
                to="/about"
                className="me-3 text-decoration-none text-black"
              >
                About
              </Link>
              <Link
                to="/products"
                className="me-3 text-decoration-none text-black"
              >
                Products
              </Link>
              <Link
                to="/contact"
                className="me-3 text-decoration-none text-black"
              >
                Contact
              </Link>
              <button className="branding_button" onClick={()=> setShow(true)}>Get a Quote</button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ContactModal show={show} setShow={setShow} />
    </>
  );
};

export default React.memo(NavbarComponent);

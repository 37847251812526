import React, { useRef, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaMailBulk,
  FaLinkedin,
} from "react-icons/fa";
import { notification } from "../utils/notification";
import axios from "axios";
import "./Contact.css";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const nameRef = useRef("");
  const emailRef = useRef("");
  const phoneRef = useRef("");
  const companyRef = useRef("");
  const detailsRef = useRef("");

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    if (!validateForm()) {
      notification("Please fill all mandatory fields in the form.", "error");
      setLoading(false);
      return; // Exit function if validation fails
    }

    const formData = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      phoneNumber: phoneRef.current.value,
      company: companyRef.current.value,
      otherDetails: detailsRef.current.value,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_FRONTEND_URL}/api/sendContactInfo`,
        formData
      );
      if (response.status === 200) {
        notification("Email send successfully", "success");
        clearForm();
      }
    } catch (error) {
      notification(
        "500 - Internal Server Error, Please try after some time.",
        "error"
      );
    }
    setLoading(false);
  };

  const clearForm = () => {
    nameRef.current.value = "";
    emailRef.current.value = "";
    phoneRef.current.value = "";
    companyRef.current.value = "";
    detailsRef.current.value = "";
  };

  const validateForm = () => {
    // Retrieve form values from refs
    const name = nameRef.current.value.trim();
    const email = emailRef.current.value.trim();
    const phone = phoneRef.current.value.trim();
    const company = companyRef.current.value.trim();

    // Validation rules
    if (name === "") {
      return false;
    }

    // Email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return false;
    }

    // Phone number validation (basic check)
    if (phone === "") {
      return false;
    }

    // Company name or website validation
    if (company === "") {
      return false;
    }

    // If all validations pass, return true
    return true;
  };

  return (
    <div>
      <Navbar />
      {/* Body */}
      <Container fluid className="p-0 position-relative ">
        <Row>
          <Col>
            <LazyLoadImage
              effect="blur"
              src="/images/contact-hero.webp"
              width="100%"
              style={{
                height: "80vh",
                objectFit: "cover",
              }}
              alt="contact phone"
              placeholderSrc="/images/placeholder.webp"
            />
            <h1 className="contact-hero__heading">Contact Us</h1>
          </Col>
        </Row>
      </Container>
      <section id="about" className="py-5 lh-lg">
        <Container>
          <Row>
            <Col>
              <h1>Get In Touch With Us</h1>
              <p className="mt-3">
                At KG PACKERS, we are committed to delivering exceptional
                customer service and support. Whether you have questions about
                our products or need guidance on choosing the right packaging
                solutions, we are here to help. We specialize in providing
                custom packaging solutions tailored to meet your specific needs
                and branding requirements. Our team is dedicated to ensuring
                every interaction with us is informative, seamless, and exceeds
                your expectations. Feel free to reach out to us to learn more
                about how we can assist you with your packaging needs.
              </p>

              <Row className="my-5">
                <Col md={6} className="contact__item">
                  <div className="d-flex">
                    <div className="contact__icon">
                      <FaMapMarkerAlt size={32} />
                    </div>
                    <div>
                      <p className="m-0 fw-bold">Our Location</p>
                      <p className="m-0 p-0 lh-sm">
                        Thamarachal, Kizhakkambalam P.O, <br /> Ernakulam,
                        Kerala 683562, India
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={6} className="contact__item">
                  <div className="d-flex">
                    <div className="contact__icon">
                      <FaPhoneAlt size={32} />
                    </div>
                    <div>
                      <p className="m-0 fw-bold">Phone Number</p>
                      <p className="m-0 lh-sm">
                        +91 - 9446097686 <br />
                        +1 (226) 899-7760 (US, Canada)
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={6} className="contact__item">
                  <div className="mt-4 lh-sm d-flex">
                    <div className="contact__icon">
                      <FaMailBulk size={32} />
                    </div>
                    <div>
                      <p className="m-0 fw-bold">Email Address</p>
                      <p className="m-0">sales@kgpackers.com</p>
                    </div>
                  </div>
                </Col>
                <Col md={6} className="contact__item">
                  <div className="mt-4 lh-sm d-flex">
                    <div className="contact__icon">
                      <FaLinkedin size={32} />
                    </div>
                    <div>
                      <p className="m-0 fw-bold">Linkedin</p>
                      <a
                        href="https://www.linkedin.com/company/kg-packers/mycompany/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.linkedin.com/company/kg-packers/mycompany/
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mt-5 d-flex align-items-center ">
                <Col md={6} className="contact__container">
                  <h2 className="mb-3">Contact Form</h2>
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        ref={nameRef}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Email address <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        ref={emailRef}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPhone">
                      <Form.Label>
                        Phone Number <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Phone Number"
                        ref={phoneRef}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="formBasicCompanyName"
                    >
                      <Form.Label>
                        Company name or company website{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Company name or company website"
                        ref={companyRef}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="formBasicOtherDetails"
                    >
                      <Form.Label>Other Details</Form.Label>
                      <textarea
                        className="form-control"
                        id="formBasicOtherDetails"
                        rows="3"
                        placeholder="Other Details"
                        ref={detailsRef}
                      ></textarea>
                    </Form.Group>
                    <div className="d-grid gap-2">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </Form>
                </Col>
                <Col md={6} className="maps__container">
                  <iframe
                    title="google_map_kg_packers"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3928.6686574994064!2d76.39930128506356!3d10.04417684666902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080bc9ff851e1b%3A0xa7941191b0c078be!2sKG%20PACKERS!5e0!3m2!1sen!2sca!4v1720829215426!5m2!1sen!2sca"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {/* end of Body */}
      <Footer />
    </div>
  );
};

export default React.memo(Contact);

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./screens/Home";
import "./App.css";
import About from "./screens/About";
import Products from "./screens/Products"
import Contact from "./screens/Contact"

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    
    </div>
  );
}

export default App;

import React from "react";
import { Col, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./SingleProduct.css"

const SingleProduct = ({ title, description, image }) => {
  return (
    <Row className="single-product_container ">
      <Col>
        <h5 className="fw-bold">{title}</h5>
        <p>{description}</p>
      </Col>
      <Col className="d-flex justify-content-center align-items-center">
        <LazyLoadImage
        effect="blur" 
        src={image}  
        className="single-product__image"
        alt={description}  />
      </Col>
    </Row>
  );
};



export default React.memo(SingleProduct);
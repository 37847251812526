import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaMapMarkerAlt, FaPhoneAlt, FaMailBulk } from "react-icons/fa";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils/scroll";
import "./Footer.css";

const Footer = () => {
  return (
    <div>
      <footer
        id="footer"
        className="py-5"
        style={{
          backgroundColor: "#ffbf4a",
        }}
      >
        <Container fluid className="px-5">
          <Row>
            <Col sm={12} md={6}>
              <h3 className="display-6">KG Packers</h3>
              <p className="lh-lg">
                KG Packers is a renowned provider of corrugated packaging
                solutions,
                <br /> recognized as a leading manufacturer, supplier, and
                exporter of
                <br /> corrugated boxes in India. Our offerings encompass a
                comprehensive
                <br /> and cost-effective one-stop solution to fulfill all your
                corrugated box needs.
              </p>
            </Col>
            <Col className="mt-2" sm={12} md={2}>
              <h4>Quick Links</h4>
              <ul className="list-unstyled">
                <li>
                  <Link to="/" className="text-decoration-none text-black" onClick={scrollToTop}>
                    Home
                  </Link>
                </li>
                <li className="my-2">
                  <Link to="/about" className="text-decoration-none text-black" onClick={scrollToTop}>
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/products"
                    className="text-decoration-none text-black"
                    onClick={scrollToTop}
                  >
                    Products
                  </Link>
                </li>
                <li className="my-2">
                  <Link
                    to="/contact"
                    className="text-decoration-none text-black"
                    onClick={scrollToTop}
                  >
                    Contact
                  </Link>
                </li>
                {/* <li>Get a Quote</li> */}
              </ul>
            </Col>
            <Col
              className="mt-2 d-flex flex-col justify-content-start"
              sm={12}
              md={4}
            >
              <div>
                <h4 className="mb-3">Contact Us</h4>
                <div className="d-flex">
                  <span className="footer__icon">
                    <FaMapMarkerAlt size={32} />
                  </span>
                  <p>
                    KG Packers - Thamarachal, Kizhakkambalam,
                    <br /> Ernakulam, Kerala 683562, India
                  </p>
                </div>
                <p>
                  <span className="d-flex">
                    <span className="footer__icon">
                      <FaPhoneAlt size={32} />
                    </span>
                    +91 - 9446097686 <br />+1 (226) 899-7760 (US, Canada)
                  </span>
                </p>
                <p>
                  <span className="d-flex">
                    <span className="footer__icon">
                      <FaMailBulk size={32} />
                    </span>
                    <span>sales@kgpackers.com</span>
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default React.memo(Footer);

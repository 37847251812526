import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import SingleProduct from "../components/SingleProduct/SingleProduct";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./Products.css";

const Products = () => {
  return (
    <div>
      <Navbar />
      {/* Hero */}
      <Container fluid className="p-0 position-relative">
        <Row>
          <Col>
            <LazyLoadImage
              effect="blur"
              src="/images/products-hero.webp"
              width="100%"
              style={{
                height: "80vh",
                objectFit: "cover",
              }}
              alt="cardboard product"
            />
            <h1 className="hero__heading">Products</h1>
          </Col>
        </Row>
      </Container>
      {/* End of Hero */}
      {/* Start of section */}
      <section id="products" className="py-5">
        <Container fluid>
          <Row className="d-flex justify-content-around equal-height">
            <Col lg={5} className="single__product">
              {/* Single Product */}
              <SingleProduct
                title="Corrugated Boxes"
                description="Choose from 3, 5, or 7 ply boxes tailored to meet a range of lightweight to heavy-duty requirements. We provide printing solutions ranging from single color to fully customized multi-color prints."
                image="/images/corrugated-boxes.webp"
              />
            </Col>
            {/* End of Single Product */}
            <Col lg={5} className="single__product">
              <SingleProduct
                title="Corrugated Rolls"
                description="Can be manufactured to your specifications with a variety of GSM and BF options. Easily adapts to any shape. Ideal for cushioning and shock absorption, making it perfect for packaging fragile items and irregularly shaped products."
                image="/images/corrugated-rolls.webp"
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-around equal-height">
            <Col lg={5} className="single__product">
              <SingleProduct
                title="Corrugated Boards"
                description="We offer an extensive range of corrugated paper boards, specially designed for creating attractive corrugated boxes. Catering to your diverse requirements, these boards are available in various qualities and dimensions to meet your specific needs."
                image="/images/corrugated-boards.webp"
              />
            </Col>
            <Col lg={5} className="single__product">
              <SingleProduct
                title="Mono-Cartons"
                description="Eye-catching promotional packaging for compact products like medicines, cosmetics, and food enhances product appeal and brand visibility. Designed to protect and present items effectively, ensuring they stand out in retail and online environments."
                image="/images/mono-carton.webp"
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-around equal-height">
            <Col lg={5} className="single__product">
              <SingleProduct
                title="Die-Cut Boxes"
                description="Our die-cut boxes offer custom shapes and sizes to fit your unique packaging needs. Made from high-quality corrugated cardboard, they provide durability and protection. Ideal for complex designs and special packaging requirements."
                image="/images/die-cut.webp"
              />
            </Col>
            <Col lg={5} className="single__product">
              <SingleProduct
                title="Pizza Boxes"
                description="Our pizza boxes are designed to meet any customer specification, ensuring your pizzas stay hot and fresh during delivery. Made from sturdy, grease-resistant corrugated cardboard, they feature secure locking and ventilation holes to ensure your pizza arrives in perfect condition."
                image="/images/pizza-box.webp"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default React.memo(Products);

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./About.css";

const About = () => {
  return (
    <div>
      <Navbar />
      {/* Hero */}
      <Container fluid className="p-0 position-relative ">
        <Row>
          <Col>
            <LazyLoadImage
              src="/images/about-us.webp"
              effect="blur"
              width="100%"
              style={{
                height: "80vh",
                objectFit: "cover",
              }}
            />
            <h1 className="hero__heading">About Us</h1>
          </Col>
        </Row>
      </Container>
      {/* End of Hero */}
      {/* Start of section */}
      <section id="about" className="py-5 lh-lg">
        <Container>
          <Row>
            <Col>
              <p>
                <span className="fw-bold">KG PACKERS</span> is a premier
                corrugated box manufacturing company with a rich legacy dating
                back to 1999. For over 25 years, we have been at the forefront
                of the packaging industry, delivering high-quality, innovative
                packaging solutions that meet the diverse needs of our clients.
              </p>
              <h4 className="mt-5">What We Do</h4>
              <p>
                At KG PACKERS, we specialize in a wide range of corrugated
                packaging products, including:
              </p>
              <ul>
                <li>
                  Corrugated Boxes: Durable and customizable to suit various
                  applications.
                </li>
                <li>
                  Printed Cartons: High-quality printing for brand recognition
                  and aesthetic appeal.
                </li>
                <li>
                  Die-Cut Boxes: Precision-cut for unique shapes and designs.
                </li>
                <li>
                  Corrugated Rolls: Versatile and reliable for different
                  packaging requirements.
                </li>
                <li>
                  Monocarton: Single-layered cartons for lightweight and
                  efficient packaging.
                </li>
                <li>
                  And Many More: We offer a comprehensive range of packaging
                  solutions to cater to all your needs.
                </li>
              </ul>
              <h4 className="mt-5">Our Expertise</h4>
              <p>
                With over a quarter-century of expertise, we have honed our
                skills and technology to provide exceptional products and
                services. Our commitment to quality, innovation, and customer
                satisfaction sets us apart in the industry.
              </p>
              <h4 className="mt-5">Why Choose Us?</h4>
              <ul>
                <li>
                  Experience: A proven track record with over 25 years in the
                  packaging industry.
                </li>
                <li>
                  Quality: Top-notch materials and manufacturing processes
                  ensure durability and reliability.
                </li>
                <li>
                  Customization: Tailored solutions to meet specific client
                  needs.
                </li>
                <li>
                  Innovation: Continual investment in technology and processes
                  to stay ahead of industry trends.
                </li>
              </ul>
              <h4 className="mt-5">Our Mission</h4>
              <p>
                To provide superior packaging solutions that enhance our
                clients' operations and protect their products, all while
                maintaining a steadfast commitment to quality, sustainability,
                and innovation.
              </p>
              <h4 className="mt-5">Connect With Us</h4>
              <p>
                To provide superior packaging solutions that enhance our
                clients' operations and protect their products, all while
                maintaining a steadfast commitment to quality, sustainability,
                and innovation.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* End of section */}
      <Footer />
    </div>
  );
};

export default React.memo(About);

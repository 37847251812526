import Noty from "noty";

// Types: alert, success, warning, error, info/information
// Layout: top, topLeft, topCenter, topRight, center, centerLeft, centerRight, bottom, bottomLeft, bottomCenter, bottomRight
export const notification = (text, type) => {
  new Noty({
    type,
    layout: "topRight",
    text,
    timeout: 2000,
  }).show();
};
